export default {
  ptBr(date) {
    if (!date) return null

    const [year, month, day] = date.split('-')
    return `${day}/${month}/${year}`
  },

  iso(date) {
    if (!date) return null

    const [day, month, year] = date.split('/')
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
  },

  getFirstDayOfMonth() {
    var today = new Date()
    var year = today.getFullYear()
    var month = today.getMonth() + 1 // JavaScript months are zero-based
    var day = 1 // Set the day to 1 for the first day of the month

    // Add leading zeros if necessary
    var formattedMonth = month.toString().padStart(2, '0')
    var formattedDay = day.toString().padStart(2, '0')

    var formattedDate = formattedDay + '/' + formattedMonth + '/' + year
    return formattedDate
  },

  getLastDayOfMonth() {
    var today = new Date()
    var year = today.getFullYear()
    var month = today.getMonth() + 1 // JavaScript months are zero-based

    // Get the first day of the next month
    var nextMonth = month === 12 ? 1 : month + 1
    var nextYear = month === 12 ? year + 1 : year
    var firstDayOfNextMonth = new Date(nextYear, nextMonth - 1, 1)

    // Subtract 1 day from the first day of the next month
    var lastDay = new Date(firstDayOfNextMonth.getTime() - 1)

    var formattedMonth = month.toString().padStart(2, '0')
    var formattedDay = lastDay
      .getDate()
      .toString()
      .padStart(2, '0')

    var formattedDate = formattedDay + '/' + formattedMonth + '/' + year
    return formattedDate
  },
}
